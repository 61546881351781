<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список аккаунтов</h2>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          v-if="isAdmin"
          @click="createFirebase"
        >Добавить аккаунт</b-button>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="firebases"
      class="users-table"
    >
      <b-table-column label="ID" v-slot="props">
        <span>{{ props.row.id }}</span>
      </b-table-column>

      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.name }}</span>
      </b-table-column>

      <b-table-column label="Прокси" v-slot="props">
        <span>{{ (props.row.ip_proxy && props.row.ip_proxy !== '') ? props.row.ip_proxy : 'Нет' }}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">

        <div>
          <b-tooltip label="Перейти">
            <b-button class="edit_icon" @click="redirectToFirebase(props.row.id)">
              <svg xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 64 64;" viewBox="0 0 64 64" xml:space="preserve">
                    <g>
                      <g transform="translate(382.000000, 380.000000)">
                        <!-- fill="#6d757d" -->
                        <polyline points="-352.3,-343.4 -354.6,-345.7 -328.8,-371.4 -326.6,-369.2 -352.3,-343.4    "/>
                        <polyline points="-326,-354.9 -329.4,-354.9 -329.4,-368.6 -343.1,-368.6 -343.1,-372 -326,-372      -326,-354.9    "/>
                        <path d="M-334.6-324h-34.3c-2.8,0-5.1-2.3-5.1-5.1v-34.3c0-2.8,2.3-5.1,5.1-5.1h18.9v3.4h-18.9     c-0.9,0-1.7,0.8-1.7,1.7v34.3c0,0.9,0.8,1.7,1.7,1.7h34.3c0.9,0,1.7-0.8,1.7-1.7V-348h3.4v18.9C-329.4-326.3-331.7-324-334.6-324     " />
                      </g>
                    </g>
                  </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Редактировать" v-if="isAdmin">
            <b-button class="edit_icon" @click="updateFirebase(props.row.id)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить" v-if="isAdmin">
            <b-button
              class="delete_icon"
              @click="deleteFirebase(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>

      </b-table-column>
    </b-table>

    <OverlayLoader :show="isLoading"></OverlayLoader>
    <FirebaseDetailsModal></FirebaseDetailsModal>
    <FirebaseConfirmDeleteModal></FirebaseConfirmDeleteModal>

  </div>
</template>

<script>
import FirebaseDetailsModal from '@/app/client/firebase/shared/modals/firebaseDetailsModal.vue';
import FirebaseConfirmDeleteModal from '@/app/client/firebase/shared/modals/confirmDeletionFirebaseModal.vue';
import OverlayLoader from "@/app/shared/components/app-loader/Loader";

export default {
  name: "FirebaseList",
  components: {
    OverlayLoader,
    FirebaseDetailsModal,
    FirebaseConfirmDeleteModal
  },
  data() {
    return {
    };
  },
  created() {
    this.getFirebases();
  },
  computed: {
    isAdmin() {
      return this.$store.getters.IS_ADMIN;
    },
    firebases() {
      return this.$store.getters.GET_FIREBASES;
    },
    detailOpened: {
      get() {
        return this.$store.getters.GET_FIREBASE_DETAILS_MODAL_OPENED;
      },
      set(value) {
        this.$store.commit("SET_FIREBASE_DETAILS_MODAL_OPENED", value);
      },
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_FIREBASES_LOADING;
      },
      set(value) {
        this.$store.commit("SET_FIREBASES_LOADING", value);
      },
    },
  },
  methods: {
    redirectToFirebase(firebase_id) {
      this.$router.push(`/firebases/${firebase_id}/collections`);
    },
    getFirebases() {
      this.$store.dispatch("GET_FIREBASES").catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    updateFirebase(id) {
      this.$store.dispatch("GET_FIREBASE_BY_ID", id).then(() => {
        this.detailOpened = true;
      }).catch(() => {
        this.$toast.error("Ошибка при получении данных");
      });
    },
    deleteFirebase(firebase) {
      this.$store.commit("SET_CURRENT_FIREBASE", firebase);
      this.$store.commit('SET_CONFIRM_DELETION_FIREBASE_MODAL', true);
    },
    createFirebase() {
      this.$store.commit("SET_CURRENT_FIREBASE", {});
      this.detailOpened = true;
    },
  },
};
</script>

<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
