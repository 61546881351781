<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentFirebase.name === undefined">
            <h2 class="title is-3">Создать партнера</h2>
          </div>
          <div class="column" v-if="currentFirebase.name !== undefined">
            <h2 class="title is-3">Изменить партнера: {{ currentAccountName }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentFirebase.name"></b-input>
            </b-field>

            <b-field label="Прокси">
              <b-input v-model="currentFirebase.ip_proxy"></b-input>
            </b-field>

            <p>
              Например http://192.168.255.201:8001
            </p>

            <b-field label="Api Key">
              <b-input v-model="currentFirebase.api_key_proxy"></b-input>
            </b-field>

            <p class="error--text" v-if="currentFirebase.has_file">
              Файл уже загружен. Не загружайте новый, если не хотите ничего менять.
            </p>

            <b-field>
                <b-upload
                  v-model="currentFirebase.files"
                  :multiple="false"
                  drag-drop
                  class="full-width-upload"
                  accept=".json"
                  @input="handleFileUpload"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                          icon="upload"
                          size="is-large"
                        />
                      </p>
                      <p v-if="currentFirebase.id == null">Перетащите ваш JSON файл сюда или кликните для загрузки</p>
                      <p v-else>Для изменения JSON файла перетащите его сюда</p>
                    </div>
                  </section>
                </b-upload>
            </b-field>

            <div class="tags">
              <span v-for="(file, index) in currentFirebase.files"
                :key="index"
                class="tag is-primary">
                {{ file.name }}
                <button class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)">
                </button>
              </span>
            </div>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentFirebase.id == null" @click="createFirebase()">Добавить</v-btn>
        <v-btn text color="primary" v-if="currentFirebase.id != null" @click="updateFirebase()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';
import { toFormData } from '@/app/shared/services/helpers';

export default {
  name: 'FirebaseDetailsModal',
  components: {
    OverlayLoader,
  },
  data() {
    return {
      currentAccountName: '',
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.currentAccountName = this.currentFirebase.name;
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.GET_FIREBASE_DETAILS_MODAL_LOADING;
    },
    isOpen: {
      get() {
        return this.$store.getters.GET_FIREBASE_DETAILS_MODAL_OPENED;
      },
      set() {
        this.$store.commit('SET_FIREBASE_DETAILS_MODAL_OPENED', false);
      },
    },
    currentFirebase: {
      get() {
        return this.$store.getters.GET_CURRENT_FIREBASE;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_FIREBASE", value);
      },
    },
  },
  methods: {
    handleFileUpload(file) {
      // Проверяем, что файл является JSON
      if (file && file.type === 'application/json') {
        this.currentFirebase.files = [file];
      } else {
        this.$toast.error("Пожалуйста, выберите только JSON файл.");
      }
    },
    deleteDropFile(index) {
      this.currentFirebase.files.splice(index, 1); // Удаляем файл из массива files
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    close() {
      this.currentFirebase = {}; // Очистка текущего Firebase
      this.$store.commit('SET_FIREBASE_DETAILS_MODAL_OPENED', false);
    },
    createFirebase() {
      this.$store.dispatch('CREATE_FIREBASE', this.customToFormData(this.currentFirebase))
        .then(() => {
          this.$toast.success("Аккаунт создан!");
        })
        .catch((error) => {
          this.$toast.error(error);
        }).finally(() => {
        this.$store.dispatch("GET_FIREBASES");
        this.close();
        });
    },
    updateFirebase() {
      let data = {
        id: this.currentFirebase.id,
        form: this.customToFormData(this.currentFirebase)
      }
      this.$store.dispatch('UPDATE_FIREBASE', data)
        .then(() => {
          this.$toast.success("Firebase успешно изменен");
          this.$store.dispatch("GET_FIREBASES");
          this.close();
        })
        .catch(() => {
          this.$toast.error("Ошибка при изменении Firebase");
        });
    },
    customToFormData(params) {
      const formData = new FormData();

      // Добавляем все ключи, кроме файлов
      Object.keys(params).forEach(key => {
        if (key !== 'files') {
          formData.append(key, params[key]);
        }
      });

      // Добавляем файлы отдельно, если они есть
      if (params.files && Array.isArray(params.files)) {
        params.files.forEach(file => {
          if (file instanceof File) {
            formData.append('files', file);
          }
        });
      }

      return formData;
    }
  },
};
</script>

<style>
.full-width-upload {
  width: 100%;
  display: block;
}

.full-width-upload .section {
  width: 100%;
}

.full-width-upload .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
